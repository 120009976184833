import {
  useStoryblokState,
  getStoryblokApi,
  StoryblokComponent,
} from "@storyblok/react";
import { useSession } from "next-auth/react";
import { QueryClient } from "@tanstack/react-query";
import { sbBlok } from "../types/sb-types";
import SignupModal from "../components/SignupModal/SignupModal";
import { useUI } from "../context/UIContext";
import JoinFlowInfoModal from "../components/JoinFlowInfoModal/JoinFlowInfoModal";
import {
  getActualTournament,
  sortTournamentsActualFirst,
} from "../utils/tournaments";
import { Tournament } from "../types/tournament";
import { useTmSignOut } from "../hooks/useTmSignOut";
import { useStartSignup } from "../hooks/useStartSignup";
import { useStartVerifyEmail } from "../hooks/useStartVerifyEmail";
import { useEffect, useState } from "react";
import {
  getAccessToken,
  getTournamentOverviewList,
} from "../utils/react-query-utils";

export default function Home({
  story,
  preview,
  newsStoriesData,
  proTournaments,
  teaserRowTournament,
}: any) {
  story = useStoryblokState(story, {}, preview);
  const { state: uiState } = useUI();
  const { data: session }: { data: any; status: string } = useSession();
  const [verifyEmailFlow, setVerifyEmailFlow] = useState<string | null>("");
  const [ygbActive, setYgbActive] = useState<string | null>("");

  useTmSignOut();
  useStartSignup();
  useStartVerifyEmail();

  useEffect(() => {
    if (verifyEmailFlow !== window.localStorage.getItem("verify_email_flow")) {
      setVerifyEmailFlow(window.localStorage.getItem("verify_email_flow"));
    }
    if (ygbActive !== window.localStorage.getItem("ygb_active")) {
      setYgbActive(window.localStorage.getItem("ygb_active"));
    }
  }, [uiState]);

  return (
    <>
      <SignupModal
        isOpen={
          uiState.isSignupModalOpen &&
          ygbActive === "true" &&
          session?.accessToken
        }
        tmToken={session?.accessToken}
        idTokenHint={session?.idToken}
      />
      <JoinFlowInfoModal
        isOpen={
          uiState.joinFlowInfoModal.isOpen &&
          ((session?.accessToken &&
            !uiState.isEmailVerified &&
            verifyEmailFlow === "true" &&
            uiState.joinFlowInfoModal.modalType === "email") ||
            uiState.joinFlowInfoModal.modalType === "signup")
        }
        tmToken={session?.accessToken}
        idTokenHint={session?.idToken}
      />
      {story?.content?.body?.map((c: sbBlok) => {
        if (c.component === "teaser_row") {
          return (
            <StoryblokComponent
              key={c._uid}
              blok={c}
              tournament={teaserRowTournament}
            />
          );
        }
        if (c.component === "blog_posts_overview") {
          return (
            <StoryblokComponent
              key={c._uid}
              blok={c}
              stories={newsStoriesData.stories}
              isFirstPostLarge={false}
            />
          );
        }
        if (
          c.component === "tournament_card_overview" &&
          proTournaments?.length > 0
        ) {
          return (
            <StoryblokComponent
              key={c._uid}
              blok={c}
              story={story}
              tournaments={proTournaments}
            />
          );
        }
        return <StoryblokComponent key={c._uid} blok={c} />;
      })}
    </>
  );
}

export async function getStaticProps(context: any) {
  const slug = "home";

  const sbParams = {
    version: process.env.VERCEL_ENV === "production" ? "published" : "draft",
    resolve_links: "url",
  };

  if (context?.preview) {
    sbParams.version = "draft";
  }

  const storyblokApi = getStoryblokApi();
  const { data: sbData } = await storyblokApi.get(
    `cdn/stories/${slug}`,
    sbParams
  );

  const { data: newsStoriesData } = await storyblokApi.get(`cdn/stories/`, {
    version: process.env.VERCEL_ENV === "production" ? "published" : "draft",
    starts_with: "news",
    is_startpage: "false",
    resolve_links: "url",
    sort_by: "first_published_at:desc",
    page: 1,
    per_page: 4,
  });

  let tournamentListData;

  const accessToken = await getAccessToken({
    processEnv: process.env.VERCEL_ENV,
    clientId: process.env.TFM_PORTAL_CLIENT_ID,
    clientIdDev: process.env.TFM_PORTAL_CLIENT_ID_DEV,
    clientSecret: process.env.TFM_PORTAL_CLIENT_SECRET,
    clientSecretDev: process.env.TFM_PORTAL_CLIENT_SECRET_DEV,
  });

  try {
    const queryClient = new QueryClient();
    const { data: tournamentList } = await queryClient.fetchQuery(
      ["tournament_overview_list"],
      () =>
        getTournamentOverviewList({
          processEnv: process.env.VERCEL_ENV,
          accessToken,
        })
    );
    tournamentListData = tournamentList;
  } catch (err: any) {
    console.log(`===> error: ${err.message}`);
  }

  const proTournaments =
    tournamentListData?.data?.node?.tournaments?.list?.items?.filter(
      (tournament: Tournament) => {
        return tournament?.keyValues?.find((keyValue) => {
          return process.env.VERCEL_ENV === "production"
            ? keyValue.key === "NextTour:TournamentKind" &&
                keyValue.value.toLowerCase() === "pro"
            : keyValue.key === "NextTour:TournamentKind" &&
                keyValue.value.toLowerCase() === "pro";
          // keyValue.value.toLowerCase() === "test";
        });
      }
    );

  return {
    props: {
      story: sbData ? sbData.story : false,
      key: sbData ? sbData.story.id : false,
      preview: context?.preview || false,
      newsStoriesData,
      proTournaments: sortTournamentsActualFirst(proTournaments) || null,
      teaserRowTournament: getActualTournament(proTournaments)?.[0] || null,
    },
    revalidate: 10,
  };
}
